import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    paramName: String,
    paramContent: String,
    url: String
  }

  getFormData() {
    if (this.hasParamNameValue && this.hasParamContentValue) {
      const formData = new FormData()

      formData.append(
        this.paramNameValue,
        this.paramContentValue
      )

      return formData
    }

    return null;
  }

  recordDismissal() {
    if (this.hasUrlValue) {
      fetch(this.urlValue, {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: this.getFormData()
      })
    }
  }
}